/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.intervention {
  margin: 20px 5%;
  padding: 20px !important;
  border: 1px solid #ccc;
  border-radius: 12px;
  align-self: center;
  align-content: center;
}

.hide{
  display: none;
  margin-bottom: 15px;
}

.banner{
  width: 100% !important;
  overflow: hidden;
  margin-bottom: 10px !important;

}
.banner img {
  max-height: 150px !important;
  object-fit: cover !important;
}

.question {
  width: 80%;
  margin: 0px auto 0px auto;
  padding-top: 15px;
  padding-bottom: 10px;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  p{
    margin: 0;
    line-height: 1.42em;
  }
  h1, h2, h3, h4, h5, h6{
    font-weight: bold !important;
    margin: 0;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.67em;
  }
}
.smallLayout .question {
  width: 60%;
}
details .question {
  width: 100%;
}

// sets default color theme for progress bars
ion-progress-bar{
  --progress-background: var(--ion-color-primary);
  --background: var(--progress-background-color);
}

app-question-headline.question > div.hide{
  margin-bottom: 0px;
}

@media only screen and (max-width: 540px){
  .question{
    width: 95%;
  }
}

// needed to use color=... for ionic elements
.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-page-body {
  --ion-color-base: var(--ion-color-page-body);
  --ion-color-shade: var(--ion-color-page-body-shade);
  --ion-color-tint: var(--ion-color-page-body-tint);
}

.ion-color-page-font {
  --ion-color-base: var(--ion-color-page-font);
  --ion-color-shade: var(--ion-color-page-font-shade);
  --ion-color-tint: var(--ion-color-page-font-tint);
}

.ion-color-header {
  --ion-color-base: var(--header-color);
}

.ion-color-font-header {
  --ion-color-base: var(--font-color-header);
}

.ion-color-button{
  --ion-color-base: var(--button-color);
}

// overlays
// for better readability you can overwrite background inline f.e.
// <div class="overlayClass" style="background: rgba(0, 0, 0, 0.8);">
.overlayClass{
  position: fixed;
  width: 100%;
  height: 100%;
  background: var(--overlay);
  // background: rgba(43, 76, 138, 0.7); blau
  // background: rgba(199, 67, 84, 0.4); rot
  top: 0;
  left: 0;
  padding-top: 80px;
  overflow-y: scroll;
}
.overlayAnnouncements {
  padding-top: 210px !important;
}
.overlayAnnouncementsOtherPages {
  margin-top: 160px !important;
}

// hide scrollbar, content is still scrollable
::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none;
}

// applies to multiple and single choice choose-screen
@media only screen and (min-width: 540px) {
  .alert-wrapper.sc-ion-alert-md,
  .alert-wrapper.sc-ion-alert-ios {
    max-width: 30%;
    min-width: 20%;
    max-height: 100%;
  }

  .alert-checkbox-group.sc-ion-alert-md,
  .alert-checkbox-group.sc-ion-alert-ios,
  .alert-radio-group.sc-ion-alert-md,
  .alert-radio-group.sc-ion-alert-ios {
    max-height: 100%;
    min-width: 20%;
  }
}


.alert-tappable.alert-radio,
.alert-tappable.alert-checkbox {
  height: auto;
  contain: content;
  min-width: 20%;

}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios {
  white-space: normal;
}


// for date component
input.ng2-flatpickr-input.flatpickr-input,
input.ng2-flatpickr-input.flatpickr-input:focus,
input.ng2-flatpickr-input.flatpickr-input:active,
input.ng2-flatpickr-input.flatpickr-input.flatpickr-mobile,
input.ng2-flatpickr-input.flatpickr-input.flatpickr-mobile:active,
input.ng2-flatpickr-input.flatpickr-input.flatpickr-mobile:focus{
  width: 100%;
  background: transparent;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: var(--ion-color-page-font);
  padding: 5px;
  font-size: var(--lesson-page-default-font-height);
  outline: none;
}

ng2-flatpickr.disabled{
  input{
    color: rgba(34, 36, 40, 0.40);
  }
  input:hover{
    cursor: default;
  }
}

ng2-flatpickr.answerRequired{
  input{
    border-width: 3px !important;
    border-color: var(--ion-color-not-answered-font)  !important;
  }
}

// for text-string component
input.native-input.sc-ion-input-md,
input.native-input.sc-ion-input-ios{
  padding-bottom: 2px !important;
  padding-top: 2px !important;
  padding-left: 4px !important;
}

.modal-css::part(content) {
  --width: 40%;
  --height: 70%;
  margin: 0 0 0 50%;
  --background: transparent;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

@media only screen and (max-width: 540px){
  .modal-css::part(content) {
    border-radius: 10px 10px 0 0;

    overflow: hidden;
    top: 30%;
    left: 0;
    position: absolute;
    margin: 0 0 0 0;
    --width: 100%;
    --height: 70%;
  }
}

.pointer:hover {
  cursor: pointer;
}

.mat-menu-content{
  max-height: 300px;
}

@media only screen and (min-width: 500px) {
  ion-modal.techModal {
    --height: auto;
    --min-height: 250px;
    ::part(content) {

      .ion-page {
        position: relative;
        contain: content;

        .modal-content {
          overflow: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 690px) {
  ion-toolbar ion-title h1 {
    font-size: 12px!important;
  }
}
