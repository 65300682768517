// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
// @media (prefers-color-scheme: dark) hooks into system and checks if dark mode is enabled on the mobile phone

// global variables, that are always set.
:root{
    // before authentication
    --before-login-background: #ffffff;
    --before-login-card-color: #b7b7b7;
    //styling for lesson pages
    // if you want to change default color if none is given you have to change it in parseLessons
    --ion-color-page-body: #FEFCF0;
    --ion-color-page-body-shade: #FEFCF0;
    --ion-color-page-body-tint: #FEFCF0;

    --ion-color-page-font: #222428;
    --ion-color-page-font-shade: #222428;
    --ion-color-page-font-tint: #222428;
    --lesson-page-default-font-height: 13pt;
    --ion-color-not-answered-font: red;

    --colorImportant: #FFABB0;
    --colorTip: #FFDBa7;
    --colorInfo: #AEDCFA;
    --colorSuccess: #7CD68D;

    // created on runtime, when personal settings have been loaded.
    // --backgroundImage: url("../assets/imgs/0.jpg");
    // overlay see global.scss
}

// set when no dark mode enabled
body{
    //overlays
    --overlay: rgba(255, 255, 255, 0.7);
    --chat-overlay: rgba(255, 255, 255, 0.9);
    --lesson-overlay: rgba(0, 0, 0, 0.55);
    //header
    --header-color: rgba(255, 255, 255, 0.65);
    --font-color-header: #0D0D0D;
    //menu
    --menu-color: #FFFFFF;
    --menu-color-darker: #101e31;
    --font-color-menu: #0D0D0D;
    --marker-color: #ff1654;
    --marker-color-hover: rgba(255, 22, 84, 0.25);

    //body and body content
    --body-color: #b1afad; //not relevant anymore due usage of background image
    --card-color: #ffffff;
    --card-color-darker:  #b7b7b7;
    --card-color-lighter:  #ffffff;
    --font-color: #0D0D0D;
    --font-color-negative: #0D0D0D;
    --font-greyed-color: #78909c;
    --lesson-finished: #00a800;
    --default-font-height: 13pt;

    //used app wide for buttons and in lesson pages for focus of text-string/area component + page bullets on lesson page
    --ion-color-primary: #e21048;
    --ion-color-primary-rgb: 226, 16, 72;
    --ion-color-primary-contrast: #ffffff; // contrast used for text inside button
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #f9104a;
    --ion-color-primary-tint: #f9104a; // tint used when hovering

    //this colour is only used for the finish button in lessons
    --ion-color-success: #099838;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #0ec254;

    //used for background of progress bars
    --progress-background-color: rgba(160, 70, 100, 0.4);
}

// only set when darkTheme is enabled
body.darkTheme{
    //overlays
    --overlay: rgba(0, 0, 0, 0.55);
    --chat-overlay: rgba(0, 0, 0, 0.8);
    --lesson-overlay: rgba(0, 0, 0, 0.55);
    //header
    --header-color: #0f1e33b3;
    --font-color-header: #f1faee;
    //menu
    --menu-color: #1d3557;
    --menu-color-darker: #101e31;
    --font-color-menu: #f1faee;
    --marker-color: #ff1654;
    --marker-color-hover: rgba(255, 22, 84, 0.25);

    //body and body content
    --body-color: #b1afad; //not relevant anymore due usage of background image
    --card-color: #e2e2e2;
    --card-color-darker: #bfbfbf;
    --card-color-lighter: #ffffff;
    --font-color: #0c0c0c;
    --font-color-negative: #f1faee;
    --font-greyed-color: #78909c;
    --lesson-finished: limegreen;
    --default-font-height: 13pt;

    //used app wide for buttons and in lesson pages for focus of text-string/area component + page bullets on lesson page
    --ion-color-primary: #15417e;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #f1faee; // contrast used for text inside button
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #1858a2; // tint used when hovering

    //this colour is only used for the finish button in lessons
    --ion-color-success: #099838;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #0ec254;

    //used for background of progress bars
    --progress-background-color: rgba(40, 70, 100, 0.4);
}
